.webshop-frontpage{
	.container .frontpage_focus-single-image{
		img{
			object-fit: cover;
			width: 100% !important;
			height: auto;
		}
	}
}
.webshop-frontpage .frontpage_focus .container .row > div:last-of-type .frontpage_focus-single:last-of-type img{
	max-height: 512px;
}